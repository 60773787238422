import React from 'react';

function IconPlus(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
      <g fill="none">
        <path d="M64 64H0V0h64z"></path>
        <g stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
          <path d="M32.149 48.29V16.293"></path>
          <path d="M16.149 32.291h32"></path>
        </g>
      </g>
    </svg>
  );
}

export default IconPlus;
